import { Box, BoxProps, Theme } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";

const ContainerSx: { [key: string]: SxProps<Theme> } = {
  narrow: {
    width: {
      xs: "auto",
      lg: "964px",
      xl: "1170px",
    },
    mx: {
      xs: "15px",
      sm: "30px",
      lg: "auto",
    },
  },
  wide: {
    width: {
      xs: "auto",
    },
    mx: {
      xs: "15px",
      md: "30px",
    },
  },
};

type ContainerProps = {
  type?: keyof typeof ContainerSx;
};

export const Container = (props: BoxProps & ContainerProps) => (
  <Box
    sx={{ ...ContainerSx[props.type || "narrow"], maxWidth: "100%" }}
    {...props}
  />
);
